<script setup lang="ts">
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const storeHeader = useHeader();

/**
 * State
 */
const exitModalOpen = ref(false);
const green = computed(() => route.name === 'scan.finish');

/**
 * Methods
 */
const finishLater = (): void => {
  log('scan.exited', 'Scan exited.', { group: route.params.groupId });
  router.push({ name: 'scan.home' });
};
const finish = (): void => {
  log('scan.finish', 'Scan finished.', { group: route.params.groupId });
  exitModalOpen.value = false;
  router.push({
    name: 'scan.finish',
    params: { schoolId: route.params.schoolId, groupId: route.params.groupId },
  });
};
</script>

<template>
  <Header
    :menu="storeHeader.burger"
    :back="storeHeader.back"
    :close="storeHeader.close"
    @back="storeHeader.onBack"
    @close="() => (exitModalOpen = true)"
    logo="scan"
    v-if="
      route.meta.hasOwnProperty('header')
        ? (route.meta.header as boolean)
        : true
    "
  />

  <main
    class="flex flex-grow flex-col"
    :class="{
      'with-bg-1': route.meta?.background === 1,
      'with-bg-2': route.meta?.background === 2,
      'with-bg-3': route.meta?.background === 3,
    }"
  >
    <div
      :class="{
        'flex flex-grow flex-col space-y-4 py-6': true,
        'bg-[#16ABF2]': !green,
        'bg-[#46A54F]': green,
      }"
    >
      <Container class="space-y-4">
        <!-- Title -->
        <h1 class="text-center text-2xl font-bold text-white">
          {{ t('scan.step.scan') }}
        </h1>
      </Container>

      <NuxtPage />
    </div>

    <Modal :open="exitModalOpen" @closed="exitModalOpen = false">
      <HeadlessDialogTitle as="h3" class="text-center font-bold text-[#300E65]">
        {{ t('scan.exit-modal.title') }}
      </HeadlessDialogTitle>

      <p class="text-center text-sm text-[#300E65]">
        {{ t('scan.exit-modal.description') }}
      </p>

      <div>
        <div class="mt-6 flex flex-col justify-center gap-4 text-sm">
          <Button @click="finishLater()" color="purple">
            {{ t('scan.exit-modal.stop') }}
          </Button>
          <Button @click="exitModalOpen = false" color="purple">
            {{ t('scan.exit-modal.resume') }}
          </Button>
          <Button @click="finish()" color="red">
            {{ t('scan.exit-modal.finish') }}
          </Button>
        </div>
      </div>
    </Modal>
  </main>
</template>

<style lang="css" scoped>
.with-bg-1 {
  @apply bg-right-bottom bg-no-repeat;
  background-image: url(/images/backgrounds/background-1.svg);
}
.with-bg-2 {
  background-image: url(/images/backgrounds/background-2.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.with-bg-3 {
  background-image: url(/images/backgrounds/background-3.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
</style>
